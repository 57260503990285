import {
    DXP_GET_USER_STATS,
    DXP_GET_USER_STATS_ERROR,
    DXP_GET_USER_STATS_STATIC,
    DXP_GET_USER_STATS_STATIC_ERROR,
    DXP_GET_ALL_USER_TYPES,
    DXP_GET_ALL_USER_TYPES_ERROR,
    DXP_GET_USER_TYPES_AND_COUNT,
    DXP_GET_USER_TYPES_AND_COUNT_ERROR,
    DXP_GET_USER_TYPE,
    DXP_GET_USER_TYPE_ERROR,
    DXP_ADD_USER_TYPE,
    DXP_ADD_USER_TYPE_ERROR,
    DXP_UPDATE_USER_TYPE,
    DXP_UPDATE_USER_TYPE_ERROR,
    DXP_DELETE_USER_TYPE,
    DXP_DELETE_USER_TYPE_ERROR,
    DXP_GET_GROUPS_COUNT,
    DXP_GET_GROUPS_COUNT_ERROR,
    DXP_GET_REVENUE_STATS,
    DXP_GET_REVENUE_STATS_ERROR,
    DXP_GET_USER_STATS_LOADING

} from "../actions/types";

const initialState = {
    allUserTypes: [],
    userStatsData: null,
    userStatsStaticData: null,
    revenueStatsData: null,
    userTypesCount: [],
    groupTypesCount: [],
    userTypeById: [],
    userStatsLoading: true,
    isLoading: true,
    success: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case DXP_GET_ALL_USER_TYPES:
            return {
                ...state,
                allUserTypes: payload,
                isLoading: false,
                success: true,
            };
        case DXP_GET_USER_STATS:
            return {
                ...state,
                userStatsData: payload,
                isLoading: false,
                success: true,
            };
        case DXP_GET_USER_STATS_LOADING:
            return {
                ...state,
                userStatsLoading: payload,
            };
        case DXP_GET_USER_STATS_STATIC:
            return {
                ...state,
                userStatsStaticData: payload,
                isLoading: false,
                success: true,
            };
        case DXP_GET_REVENUE_STATS:
            return {
                ...state,
                revenueStatsData: payload,
                isLoading: false,
                success: true,
            };
        case DXP_GET_USER_TYPES_AND_COUNT:
            return {
                ...state,
                userTypesCount: payload?.userTypeCount,
                groupTypesCount: payload?.groupTypeCount,
                isLoading: false,
                success: true,
            };
        case DXP_GET_GROUPS_COUNT:
            return {
                ...state,
                groupTypesCount: payload,
                isLoading: false,
                success: true,
            };
        case DXP_GET_USER_TYPE:
            return {
                ...state,
                userTypeById: payload,
                isLoading: false,
                success: true,
            };
        case DXP_ADD_USER_TYPE:
        case DXP_UPDATE_USER_TYPE:
        case DXP_DELETE_USER_TYPE:
            return {
                ...state,
                isLoading: false,
                success: true,
            };
        case DXP_ADD_USER_TYPE_ERROR:
        case DXP_UPDATE_USER_TYPE_ERROR:
        case DXP_DELETE_USER_TYPE_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
            };
        case DXP_GET_USER_STATS_ERROR:
            return {
                ...state,
                userStatsData: null,
                isLoading: false,
                success: false,
            };
        case DXP_GET_USER_STATS_STATIC_ERROR:
            return {
                ...state,
                userStatsStaticData: null,
                isLoading: false,
                success: false,
            };
        case DXP_GET_REVENUE_STATS_ERROR:
            return {
                ...state,
                revenueStatsData: null,
                isLoading: false,
                success: false,
            };
        case DXP_GET_ALL_USER_TYPES_ERROR:
            return {
                ...state,
                allUserTypes: payload,
                isLoading: false,
                success: false,
            };
        case DXP_GET_USER_TYPES_AND_COUNT_ERROR:
            return {
                ...state,
                userTypesCount: payload,
                groupTypesCount: payload,
                isLoading: false,
                success: false,
            };
        case DXP_GET_GROUPS_COUNT_ERROR:
            return {
                ...state,
                groupTypesCount: payload,
                isLoading: false,
                success: false,
            };
        case DXP_GET_USER_TYPE_ERROR:
            return {
                ...state,
                userTypeById: payload,
                isLoading: false,
                success: false,
            };
        default:
            return state;
    }
}
