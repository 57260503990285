import {
    DXP_CHECK_SUBSCRIPTION_DISCREPANCIES,
    DXP_CHECK_SUBSCRIPTION_DISCREPANCIES_ERROR,
    DXP_CLEAR_ISSUES,
    DXP_SELECT_ISSUES,
    DXP_FIX_SUBSCRIPTION_DISCREPANCIES,
    DXP_FIX_SUBSCRIPTION_DISCREPANCIES_ERROR,
    DXP_SET_AUDIT_CSV,
    DXP_DISABLE_FIX_ISSUES,
    DXP_DISABLE_FIX_BUTTONS
} from "../actions/types";

const initialState = {
    usersWithIssues: [],
    selectedIssues: null,
    auditCSVData: [],
    fixDisabled: false,
    disableFixButtons: false,
    isLoading: true,
    success: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case DXP_CHECK_SUBSCRIPTION_DISCREPANCIES:
            return {
                ...state,
                usersWithIssues: payload,
                isLoading: false,
                success: true,
            };
        case DXP_SET_AUDIT_CSV:
            return {
                ...state,
                auditCSVData: payload,
            };
        case DXP_SELECT_ISSUES:
            return {
                ...state,
                selectedIssues: payload,
            };
        case DXP_DISABLE_FIX_ISSUES:
            return {
                ...state,
                fixDisabled: payload,
            };
        case DXP_DISABLE_FIX_BUTTONS:
            return {
                ...state,
                disableFixButtons: payload,
            };
        case DXP_CLEAR_ISSUES:
            return {
                ...state,
                usersWithIssues: [],
                selectedIssues: null,
                auditCSVData: [],
                isLoading: true,
                success: true,
            };
        case DXP_FIX_SUBSCRIPTION_DISCREPANCIES:
            return {
                ...state,
                isLoading: false,
                success: true,
            };
        case DXP_CHECK_SUBSCRIPTION_DISCREPANCIES_ERROR:
            return {
                ...state,
                usersWithIssues: [],
                isLoading: false,
                success: false,
            };
        case DXP_FIX_SUBSCRIPTION_DISCREPANCIES_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
            };
        default:
            return state;
    }
}
