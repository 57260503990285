import {
    DXP_ADD_COURSE,
    DXP_ADD_COURSE_ERROR,
    DXP_GET_ALL_COURSES,
    DXP_GET_ALL_COURSES_ERROR,
    DXP_GET_ALL_COURSES_BY_CATEGORY,
    DXP_GET_ALL_COURSES_BY_CATEGORY_ERROR,
    DXP_GET_ALL_COURSE_PARTS,
    DXP_GET_ALL_COURSE_PARTS_ERROR,
    DXP_GET_COURSES_PAGE,
    DXP_GET_COURSES_PAGE_ERROR,
    DXP_SEARCH_COURSES_PAGE,
    DXP_SEARCH_COURSES_PAGE_ERROR,
    DXP_SELECT_COURSE,
    DXP_GET_ALL_COURSE_COUNT,
    DXP_GET_ALL_COURSE_COUNT_ERROR,
    DXP_UPDATE_COURSE,
    DXP_UPDATE_COURSE_ERROR,
    DXP_DELETE_COURSE,
    DXP_DELETE_COURSE_ERROR,
    DXP_GET_ALL_COURSES_BY_LANGUAGE,
    DXP_GET_ALL_COURSES_BY_LANGUAGE_ERROR,
    DXP_SEARCH_COURSES_COUNT,
    DXP_SEARCH_COURSES_COUNT_ERROR,
    DXP_GET_COMPLETED_COURSE_DATA,
    DXP_GET_COMPLETED_COURSE_DATA_ERROR,
} from "../actions/types";

const initialState = {
    count: null,
    searchCount: null,
    courses: [],
    coursesByCategory: [],
    publishedCourses: [],
    courseParts: [],
    coursesSearchPage: [],
    coursesByLanguage: [],
    completedCourseData: [],
    selectedCourse: null,
    isLoading: true,
    success: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case DXP_GET_ALL_COURSES:
        case DXP_GET_COURSES_PAGE:
            return {
                ...state,
                courses: payload,
                publishedCourses: payload.filter(course => course.published),
                isLoading: false,
                success: true,
            };
        case DXP_GET_ALL_COURSES_BY_CATEGORY:
            return {
                ...state,
                coursesByCategory: payload,
                publishedCourses: payload.filter(course => course.published),
                isLoading: false,
                success: true,
            };
        case DXP_GET_ALL_COURSE_COUNT:
            return {
                ...state,
                count: payload,
                isLoading: false,
                success: true
            }
        case DXP_SEARCH_COURSES_COUNT:
            return {
                ...state,
                searchCount: payload,
                isLoading: false,
                success: true
            }
        case DXP_GET_ALL_COURSE_PARTS:
            return {
                ...state,
                courseParts: payload,
                isLoading: false,
                success: true
            }
        case DXP_SELECT_COURSE:
            return {
                ...state,
                selectedCourse: payload,
            };
        case DXP_SEARCH_COURSES_PAGE:
            return {
                ...state,
                coursesSearchPage: payload,
                isLoading: false,
                success: true,
            };
        case DXP_GET_ALL_COURSES_BY_LANGUAGE:
            return {
                ...state,
                coursesByLanguage: payload,
                isLoading: false,
                success: true,
            };
        case DXP_GET_COMPLETED_COURSE_DATA:
            return {
                ...state,
                completedCourseData: payload,
                isLoading: false,
                success: true,
            };
        case DXP_ADD_COURSE:
        case DXP_UPDATE_COURSE:
        case DXP_DELETE_COURSE:
            return {
                ...state,
                isLoading: false,
                success: true,
            };
        case DXP_GET_ALL_COURSES_ERROR:
        case DXP_GET_COURSES_PAGE_ERROR:
            return {
                ...state,
                courses: payload,
                isLoading: false,
                success: false,
            };
        case DXP_GET_ALL_COURSES_BY_CATEGORY_ERROR:
            return {
                ...state,
                coursesByCategory: payload,
                isLoading: false,
                success: false,
            };

        case DXP_GET_ALL_COURSE_COUNT_ERROR:
            return {
                ...state,
                searchCount: payload,
                isLoading: false,
                success: false
            }
        case DXP_SEARCH_COURSES_COUNT_ERROR:
            return {
                ...state,
                count: payload,
                isLoading: false,
                success: false
            }
        case DXP_GET_ALL_COURSE_PARTS_ERROR:
            return {
                ...state,
                courseParts: payload,
                isLoading: false,
                success: false
            }

        case DXP_SEARCH_COURSES_PAGE_ERROR:
            return {
                ...state,
                coursesSearchPage: payload,
                isLoading: false,
                success: false,
            };
        case DXP_GET_ALL_COURSES_BY_LANGUAGE_ERROR:
            return {
                ...state,
                coursesByLanguage: payload,
                isLoading: false,
                success: false,
            };
        case DXP_GET_COMPLETED_COURSE_DATA_ERROR:
            return {
                ...state,
                completedCourseData: payload,
                isLoading: false,
                success: false,
            };

        case DXP_ADD_COURSE_ERROR:
        case DXP_UPDATE_COURSE_ERROR:
        case DXP_DELETE_COURSE_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
            };
        default:
            return state;
    }
}
